<template>
  <router-view></router-view>

</template>

<script>
import ApiService from "@/services/ApiService";
export default {
  name: 'App',
  components: {
  },
  created() {
    let token = localStorage.getItem('token');
    if (token) {
      ApiService.setIsAuth(token)
    } else {
      ApiService.logout()
    }
  },
  methods: {

  },
}
</script>

<style lang="scss" scoped>

</style>
