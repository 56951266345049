import axios from "axios";
import ApiService from "@/services/ApiService";

const ApiClient = {
    requestAuthorized: function (method, url, data, params, headers = {}) {
        const token = localStorage.getItem('token') || '';

        if (!token) {
            ApiService.logout();
        }

        const defaultHeaders = {
            'X-Auth-Token': token,
            'Accept': 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded'
        }

        const requestOptions = {
            method: method,
            url: process.env.VUE_APP_API_URL + url,
            headers: Object.assign({}, defaultHeaders, headers),
            data: data,
            params: params,
        };
        return axios(requestOptions)
            .then(response => {
                return response.data;
            })
            .catch(err => {
                if (err.response.status === 401) {
                    ApiService.logout();
                }
                throw err;
            })
    },

    requestAnonymous: function (method, url, data, params, headers = {}) {
        const defaultHeaders = {
            'Accept': 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded'
        }

        const requestOptions = {
            method: method,
            url: process.env.VUE_APP_API_URL + url,
            headers: Object.assign({}, defaultHeaders, headers),
            data: data,
            params: params,
        };

        return axios(requestOptions)
            .then(response => {
                return response.data;
            })
            .catch(err => {
                if (err.response.status === 401) {
                    ApiService.logout();
                }
                throw err;
            })
    }
}

export default ApiClient