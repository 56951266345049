import {store} from "@/vuex/store";
import ApiClient from "@/services/ApiClient";
import router from "@/router";
import {ElNotification} from "element-plus";

const ApiService = {
    logout: function () {
        localStorage.removeItem('token');
        store.dispatch('unauthorized');
    },
    login: function (email, password) {
        ApiClient.requestAnonymous(
            'post',
            '/api/user/login',
            {email: email, password: password}
        ).then((res) => {
            ApiService.setIsAuth(res.data.token)
            router.push({name: 'app.dashboard'});
        }).catch((err) => {
            ElNotification.error({
                title: 'Error',
                message: (err.response.data.error.message || 'Bad credentials!'),
                offset: 100,
            })

            return err;
        })
    },
    userConfirmCode: function (code, email) {
        return ApiClient.requestAnonymous(
            'post',
            '/api/user/confirm-code',
            {
                email: email,
                code: code
            }
        ).then((res) => {
            console.log(res);
            return true;
        }).catch((err) => {
            throw err;
        })
    },
    register: function (data) {
        ApiClient.requestAnonymous(
            'post',
            '/api/user/register',
            data
        ).then(() => {
            ElNotification.success({
                title: 'Success',
                message: 'User has been registered',
                offset: 100,
            });
            router.push('/login')
        }).catch(err => {
            ElNotification.error({
                title: 'Error',
                message: err.response.data.data.message,
                offset: 100,
            });
            return err;
        })
    },
    recoveryPassword: function (email) {
        ApiClient.requestAnonymous(
            'post',
            '/api/user/recovery',
            {
                email: email,
            }
        ).then(() => {
            localStorage.setItem('recoveryEmail', email);
            router.push({name: 'check-email'})
        }).catch(err => {
            ElNotification.error({
                title: 'Error',
                message: err.response.data.error,
                offset: 100,
            })

            return err;
        })
    },
    resetPassword: function (password, passwordRepeat, token) {
        ApiClient.requestAnonymous(
            'post',
            '/api/user/reset-password/' + token,
            {
                password: {
                    first: password,
                    second: passwordRepeat
                }
            },
            {}
        ).then(() => {
            ElNotification.success({
                title: 'Success',
                message: 'Your password has been successfully changed',
                offset: 100,
            })

            router.push({name: 'login'})
        }).catch(err => {
            let message = err.response.data.error;
            if (err.response.status === 500) {
                message = 'Passwords must match!'
            }

            ElNotification.error({
                title: 'Error',
                message: message,
                offset: 100,
            })

            return err;
        })
    },
    verifyResetToken: function (token) {
        return ApiClient.requestAnonymous(
            'post',
            '/api/user/verify-token-reset-password/' + token,
            {},
            {}
        ).then((response) => {
            return response;
        }).catch(err => {
            return err;
        })
    },
    setIsAuth: function (token) {
        localStorage.setItem('token', token);
        store.dispatch('authorized');
    },
    getUsers: function (params) {
        return ApiClient.requestAuthorized(
            'get',
            '/api/admin/users',
            '',
            params
        ).then((res) => {
            return res;
        }).catch(err => {
            return err;
        });
    },
    getUsersCounter: function () {
        return ApiClient.requestAuthorized(
            'get',
            '/api/admin/users/counter',
            '',
            {}
        ).then((res) => {
            return res;
        }).catch(err => {
            return err;
        });
    },
    sendContact: function (data) {
        return ApiClient.requestAnonymous(
            'post',
            '/api/contact',
            data,
            {}
        ).then((res) => {
            return res;
        }).catch(err => {
            return err;
        });
    },
    getUser: function (id) {
        return ApiClient.requestAuthorized(
            'get',
            '/api/admin/users/' + id,
        ).then((res) => {
            return res;
        }).catch(err => {
            return err;
        });
    },
    createUser: function (data) {
        return ApiClient.requestAuthorized(
            'POST',
            '/api/admin/users',
            data
        ).then((res) => {
            return res;
        }).catch(err => {
            return err;
        });
    },
    deleteUser: function (id) {
        return ApiClient.requestAuthorized(
            'DELETE',
            '/api/admin/users/' + id,
        ).then((res) => {
            console.log(res)
        }).catch(err => {
            throw err;
        });
    },
    editUser: function (id, data) {
        return ApiClient.requestAuthorized(
            'PUT',
            '/api/admin/users/' + id,
            data
        ).then(() => {
            router.push({name: 'admin.users.list'});
        }).catch(err => {
            return err;
        });
    },
    editProfile: function (data) {
        return ApiClient.requestAuthorized(
            'PUT',
            '/api/user/edit-profile',
            data
        ).then(() => {
            router.push({name: 'app.dashboard'});
        }).catch(err => {
            throw err;
        });
    },
    getProfileData: function () {
        return ApiClient.requestAuthorized(
            'GET',
            '/api/user/view-profile'
        ).then((res) => {
            return res;
        }).catch(err => {
            return err;
        });
    },
    getUserActivity: function () {
        return ApiClient.requestAuthorized(
            'GET',
            '/api/user/activity'
        ).then((res) => {
            return res;
        }).catch(err => {
            return err;
        });
    },
    getManufactures: function (query) {
        return ApiClient.requestAuthorized(
            'GET',
            '/api/device/manufactures',
            '',
            query
        ).then((res) => {
            return res;
        }).catch(err => {
            return err;
        });
    },
    getManufacturesPV: function (query) {
        return ApiClient.requestAuthorized(
            'GET',
            '/api/device/manufactures-pv',
            '',
            query
        ).then((res) => {
            return res;
        }).catch(err => {
            return err;
        });
    },
    getCountries: function (query) {
        return ApiClient.requestAnonymous(
            'GET',
            '/api/device/country',
            '',
            query
        ).then((res) => {
            return res;
        }).catch(err => {
            return err;
        });
    },
    // Devices
    getDevices: function (query) {
        return ApiClient.requestAuthorized(
            'GET',
            '/api/device',
            '',
            query
        ).then((res) => {
            return res;
        }).catch(err => {
            return err;
        });
    },
    getDevicesRegistry: function (query) {
        return ApiClient.requestAnonymous(
            'GET',
            '/api/device/registry',
            '',
            query
        ).then((res) => {
            return res;
        }).catch(err => {
            return err;
        });
    },
    getDeviceDictionary: function () {
        return ApiClient.requestAuthorized(
            'GET',
            '/api/device/dictionary',
        ).then((res) => {
            return res;
        }).catch(err => {
            return err;
        });
    },
    deleteDevice: function (id) {
        return ApiClient.requestAuthorized(
            'DELETE',
            '/api/device/' + id,
        ).then((res) => {
            return res;
        }).catch(err => {
            return err;
        });
    },
    createDevice: function (data) {
        return ApiClient.requestAuthorized(
            'POST',
            '/api/device',
            data,
            '',
            {"Content-Type": "multipart/form-data"}
        ).then((res) => {
            return res;
        }).catch(err => {
            throw err;
        });
    },
    createAttachment: function (data) {
        return ApiClient.requestAuthorized(
            'POST',
            '/api/attachment',
            data,
            '',
            {"Content-Type": "multipart/form-data"}
        ).then((res) => {
            return res;
        }).catch(err => {
            throw err;
        });
    },
    editDevice: function (id, data) {
        return ApiClient.requestAuthorized(
            'POST',
            '/api/device/' + id,
            data,
            '',
            {"Content-Type": "multipart/form-data"}
        ).then((res) => {
            return res;
        }).catch(err => {
            throw err;
        });
    },
    getDevice: function (id) {
        return ApiClient.requestAuthorized(
            'GET',
            '/api/device/' + id,
        ).then((res) => {
            return res;
        }).catch(err => {
            return err;
        });
    },
    getStatFed: function (query) {
        return ApiClient.requestAuthorized(
            'GET',
            '/api/device/stat/fed',
            '',
            query
        ).then((res) => {
            return res.data;
        }).catch(err => {
            throw err;
        });
    },
    getStatTrend: function (query) {
        return ApiClient.requestAuthorized(
            'GET',
            '/api/device/stat/trend',
            '',
            query
        ).then((res) => {
            return res.data;
        }).catch(err => {
            return err;
        });
    },
    getStatTimePeriod: function (query) {
        return ApiClient.requestAuthorized(
            'GET',
            '/api/device/stat/time-period',
            '',
            query
        ).then((res) => {
            return res.data;
        }).catch(err => {
            return err;
        });
    },
    getBlogs: function (query) {
        return ApiClient.requestAnonymous(
            'GET',
            '/api/blog',
            '',
            query
        ).then((res) => {
            return res.data;
        }).catch(err => {
            return err;
        });
    },
    getBlog: function (slug) {
        return ApiClient.requestAnonymous(
            'GET',
            '/api/admin/blog/' + slug,
            '',
        ).then((res) => {
            return res.data;
        }).catch(err => {
            if (err.response.status === 404) {
                router.push({name: 'not-found'});
            }
        });
    },
    getTeams: function () {
        return ApiClient.requestAnonymous(
            'GET',
            '/api/team?active=1',
        ).then((res) => {
            return res.data;
        }).catch(err => {
            throw err;
        });
    },
    getTeam: function (slug) {
        return ApiClient.requestAnonymous(
            'GET',
            '/api/team/' + slug,
        ).then((res) => {
            return res.data;
        }).catch(err => {
            throw err;
        });
    },
    getHomeMetrics: function () {
        return ApiClient.requestAnonymous(
            'GET',
            '/api/home',
        ).then((res) => {
            return res.data;
        }).catch(err => {
            throw err;
        });
    },

}
export const ApiAdminService = {
    getUserActivity: function (user) {
        return ApiClient.requestAuthorized(
            'GET',
            '/api/admin/users/' + user + '/activity'
        ).then((res) => {
            return res;
        }).catch(err => {
            return err;
        });
    },
    createCountry: function (data) {
        return ApiClient.requestAuthorized(
            'POST',
            '/api/admin/device/country',
            data
        ).then((res) => {
            return res;
        }).catch(err => {
            return err;
        });
    },
    editCountry: function (id, data) {
        return ApiClient.requestAuthorized(
            'PUT',
            '/api/admin/device/country/' + id,
            data
        ).then((res) => {
            return res;
        }).catch(err => {
            return err;
        });
    },
    deleteCountry: function (id) {
        return ApiClient.requestAuthorized(
            'DELETE',
            '/api/admin/device/country/' + id,
        ).then((res) => {
            return res;
        }).catch(err => {
            return err;
        });
    },
    deleteManufacture: function (id) {
        return ApiClient.requestAuthorized(
            'DELETE',
            '/api/admin/device/manufactures/' + id,
        ).then((res) => {
            return res;
        }).catch(err => {
            throw err;
        });
    },
    deletePVManufacture: function (id) {
        return ApiClient.requestAuthorized(
            'DELETE',
            '/api/admin/device/manufactures-pv/' + id,
        ).then((res) => {
            return res;
        }).catch(err => {
            return err;
        });
    },
    createManufacture: function (data) {
        return ApiClient.requestAuthorized(
            'POST',
            '/api/admin/device/manufactures',
            data
        ).then((res) => {
            return res;
        }).catch(err => {
            return err;
        });
    },
    createPVManufacture: function (data) {
        return ApiClient.requestAuthorized(
            'POST',
            '/api/admin/device/manufactures-pv',
            data
        ).then((res) => {
            return res;
        }).catch(err => {
            return err;
        });
    },
    editManufacture: function (id, data) {
        return ApiClient.requestAuthorized(
            'PUT',
            '/api/admin/device/manufactures/' + id,
            data
        ).then((res) => {
            return res;
        }).catch(err => {
            return err;
        });
    },
    editPVManufacture: function (id, data) {
        return ApiClient.requestAuthorized(
            'PUT',
            '/api/admin/device/manufactures-pv/' + id,
            data
        ).then((res) => {
            return res;
        }).catch(err => {
            return err;
        });
    },
    // Devices
    approveDevice: function (id) {
        return ApiClient.requestAuthorized(
            'POST',
            '/api/admin/device/' + id + '/approve',
        ).then((res) => {
            return res;
        }).catch(err => {
            return err;
        });
    },
    deleteDevice: function (id) {
        return ApiClient.requestAuthorized(
            'DELETE',
            '/api/admin/device/' + id,
        ).then((res) => {
            return res;
        }).catch(err => {
            throw err;
        });
    },
    getDevices: function (query) {
        return ApiClient.requestAuthorized(
            'GET',
            '/api/admin/device',
            '',
            query
        ).then((res) => {
            return res;
        }).catch(err => {
            return err;
        });
    },
    getDeviceLogs: function (id, query) {
        return ApiClient.requestAuthorized(
            'GET',
            '/api/admin/device/' + id + '/log',
            '',
            query
        ).then((res) => {
            return res;
        }).catch(err => {
            throw err;
        });
    },
    editDevice: function (id, data) {
        return ApiClient.requestAuthorized(
            'POST',
            '/api/admin/device/' + id,
            data,
            '',
            {"Content-Type": "multipart/form-data"}
        ).then((res) => {
            return res;
        }).catch(err => {
            throw err;
        });
    },
    createBlog: function (data) {
        return ApiClient.requestAuthorized(
            'POST',
            '/api/admin/blog',
            data,
            '',
            {"Content-Type": "multipart/form-data"}
        ).then((res) => {
            ElNotification.success({
                title: 'Success',
                message: 'Blog has been created',
                offset: 100,
            });
            return res;
        }).catch(err => {
            throw err;
        });
    },
    getBlogs: function (query) {
        return ApiClient.requestAuthorized(
            'GET',
            '/api/admin/blog',
            '',
            query
        ).then((res) => {
            return res.data;
        }).catch(err => {
            throw err;
        });
    },
    getBlog: function (slug) {
        return ApiClient.requestAuthorized(
            'GET',
            '/api/admin/blog/' + slug,
            '',
        ).then((res) => {
            return res.data;
        }).catch(err => {
            return err;
        });
    },
    editBlog: function (slug, data) {
        return ApiClient.requestAuthorized(
            'POST',
            '/api/admin/blog/' + slug,
            data,
        ).then((res) => {
            return res.data;
        }).catch(err => {
            throw err;
        });
    },

    deleteBlog: function (slug) {
        return ApiClient.requestAuthorized(
            'DELETE',
            '/api/admin/blog/' + slug,
        ).then((res) => {
            return res.data;
        }).catch(err => {
            return err;
        });
    },
    getRequests: function (query) {
        return ApiClient.requestAuthorized(
            'GET',
            '/api/admin/contact',
            '',
            query
        ).then((res) => {
            return res;
        }).catch(err => {
            return err;
        });
    },
    deleteRequest: function (id) {
        return ApiClient.requestAuthorized(
            'DELETE',
            '/api/admin/contact/' + id,
            '',
        ).then((res) => {
            return res.data;
        }).catch(err => {
            return err;
        });
    },
    getSubscribers: function (query) {
        return ApiClient.requestAuthorized(
            'GET',
            '/api/admin/subscribe',
            '',
            query
        ).then((res) => {
            return res;
        }).catch(err => {
            return err;
        });
    },
    deleteSubscriber: function (id) {
        return ApiClient.requestAuthorized(
            'DELETE',
            '/api/admin/subscribe/' + id,
            '',
        ).then((res) => {
            return res.data;
        }).catch(err => {
            throw err;
        });
    },
    createSubscriber: function (data) {
        return ApiClient.requestAuthorized(
            'POST',
            '/api/user/subscribe',
            data,
        ).then((res) => {
            console.log('res', res)
            return res.data;
        }).catch(err => {
            return err;
        });
    },
    createTeamMember: function (data) {
        return ApiClient.requestAuthorized(
            'POST',
            '/api/admin/team',
            data,
        ).then((res) => {
            return res.data;
        }).catch(err => {
            throw err;
        });
    },
    editTeamMember: function (id, data) {
        return ApiClient.requestAuthorized(
            'POST',
            '/api/admin/team/' + id,
            data,
        ).then((res) => {
            return res.data;
        }).catch(err => {
            return err;
        });
    },
    getTeamList: function () {
        return ApiClient.requestAuthorized(
            'GET',
            '/api/admin/team',
        ).then((res) => {
            return res.data;
        }).catch(err => {
            return err;
        });
    },
    getTeamMember: function (id) {
        return ApiClient.requestAuthorized(
            'GET',
            '/api/admin/team/' + id,
        ).then((res) => {
            return res.data;
        }).catch(err => {
            return err;
        });
    },
    deleteTeam: function (id) {
        return ApiClient.requestAuthorized(
            'DELETE',
            '/api/admin/team/' + id,
        ).then((res) => {
            return res.data;
        }).catch(err => {
            return err;
        });
    },
    getStatFed: function () {
        return ApiClient.requestAuthorized(
            'GET',
            '/api/admin/device/stat/fed',
        ).then((res) => {
            return res.data;
        }).catch(err => {
            throw err;
        });
    },
    getStatTrend: function () {
        return ApiClient.requestAuthorized(
            'GET',
            '/api/admin/device/stat/trend',
        ).then((res) => {
            return res.data;
        }).catch(err => {
            return err;
        });
    },
}

export default ApiService;