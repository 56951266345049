const routes = [
    {
        path: '/',
        component: () => import('../layouts/MainLayout.vue'),
        children: [
            {path: '/', component: () => import('../pages/HomePage.vue')},
        ],
    },
    {
        path: '/',
        component: () => import('../layouts/PagesLayout.vue'),
        children: [
            {
                path: '/why-gcb',
                name: 'pages.why-gcb',
                component: () => import('../pages/WhyGCB.vue'),
                meta: {title: 'Why GCB?'},
            },
            {
                path: '/standard-gcb',
                name: 'pages.standard-gcb',
                component: () => import('../pages/StandardGCB.vue'),
                meta: {title: 'GCB Standard'},
            },
            {
                path: '/governance',
                name: 'pages.governance',
                component: () => import('../pages/GovernancePage.vue'),
                meta: {title: 'Governance'},
            },
            {
                path: '/careers',
                name: 'pages.careers',
                component: () => import('../pages/CareersPage.vue'),
                meta: {title: 'Careers'},
            },
            {
                path: '/team/:slug',
                name: 'pages.team.member',
                component: () => import('../components/MembersList/MemberProfile.vue'),
                meta: {title: 'Team member'},
            },
            {
                path: '/registry',
                name: 'pages.registry',
                component: () => import('../pages/RegistryPage.vue'),
                meta: {title: 'Registry'},
            },
            {
                path: '/verified-devices',
                name: 'pages.verified-devices',
                component: () => import('../pages/VerifiedDevices.vue'),
                meta: {title: 'Verified devices'},
            },
            {
                path: '/price-index',
                name: 'pages.price-index',
                component: () => import('../pages/PriceIndexPage.vue'),
                meta: {title: 'GCB Price Index'},
            },
            {
                path: '/team',
                name: 'pages.team',
                component: () => import('../pages/TeamPage.vue'),
                meta: {title: 'GCB Team'},
            },
            {path: '/faq', name: 'pages.faq', component: () => import('../pages/FAQ.vue'), meta: {title: 'FAQ'}},
            {
                path: '/get-in-touch',
                name: 'pages.connection',
                component: () => import('../pages/ConnectionPage.vue'),
                meta: {title: 'Get in Touch'}
            },
            {
                path: '/blogs',
                name: 'pages.blogs',
                component: () => import('../pages/BlogsPage.vue'),
                meta: {key: 'blogs', title: 'Blogs'}
            },
            {
                path: '/blogs/:slug',
                name: 'pages.blogs.content',
                component: () => import('../pages/BlogsContent.vue'),
                meta: {title: 'Blog'}
            },
            {
                path: '/privacy-policy',
                name: 'pages.privacy-policy',
                component: () => import('../pages/PrivacyPolicy.vue'),
                meta: {title: 'Privacy Policy'}
            },
            {
                path: '/data-processing',
                name: 'pages.data-processing',
                component: () => import('../pages/DataProcessing.vue'),
                meta: {title: 'Data Processing'}
            },
        ],
    },
    {
        path: '/login',
        component: () => import('../layouts/HeaderLayout.vue'),
        children: [
            {path: '/login', name: 'login', component: () => import('../pages/LoginPage.vue'), meta: {title: 'Login'}},
            {
                path: '/forgot-password',
                name: 'forgot-password',
                component: () => import('../pages/ForgotPassword.vue'),
                meta: {title: 'Forgot Password'}
            },
            {
                path: '/sign-up',
                name: 'sign-up',
                component: () => import('../pages/SignUp.vue'),
                meta: {title: 'Sign Up'}
            },
            {
                path: '/check-email',
                name: 'check-email',
                component: () => import('../pages/CheckEmail.vue'),
                meta: {title: 'Check Email'}
            },
            {
                path: '/enter-code-manually',
                name: 'enter-code-manually',
                component: () => import('../pages/EnterCodeManually.vue'),
                meta: {title: 'Enter Code Manually'}
            },
            {
                path: '/reset-password/:code',
                name: 'new-password',
                component: () => import('../pages/NewPassword.vue'),
                meta: {title: 'New Password'}
            },
        ],
    },
    {
        path: '/app',
        component: () => import('../layouts/DashboardLayout.vue'),
        meta: {title: 'Dashboard Layout'},
        children: [
            // Devices
            {
                path: '/app/devices',
                name: 'app.devices.list',
                component: () => import('../components/Dashboard/Devices/User/List.vue'),
                meta: {requiresAuth: true, title: 'Devices'}
            },
            {
                path: '/app/devices/create',
                name: 'app.devices.create',
                component: () => import('../components/Dashboard/Devices/User/Create.vue'),
                meta: {requiresAuth: true, title: 'New device'}
            },
            {
                path: '/app/devices/edit/:id',
                name: 'app.devices.edit',
                component: () => import('../components/Dashboard/Devices/User/Edit.vue'),
                meta: {requiresAuth: true, title: 'Edit device'}
            },

            // Device Manufactures
            {
                path: '/app/devices/manufacture',
                name: 'app.devices.manufacture.list',
                component: () => import('../components/Dashboard/Manufacture/List.vue'),
                meta: {requiresAuth: true, title: 'Manufactures'},
            },
            // Device Country
            {
                path: '/app/devices/country',
                name: 'app.devices.country.list',
                component: () => import('../components/Dashboard/Country/List.vue'),
                meta: {requiresAuth: true, title: 'Countries'},
            },

            // User
            {
                path: '/app',
                name: 'app.dashboard',
                component: () => import('../components/Dashboard/Home.vue'),
                meta: {requiresAuth: true, title: 'Home'}
            },
            {
                path: '/app/profile',
                name: 'app-profile',
                component: () => import('../components/Dashboard/ProfilePage.vue'),
                meta: {requiresAuth: true, title: 'Profile'}
            },

            // Admin Users
            {
                path: '/app/admin/users',
                name: 'admin.users.list',
                component: () => import('../pages/AppUsers.vue'),
                meta: {requiresAuth: true, title: 'Users'},
            },
            {
                path: '/app/admin/users/:id',
                name: 'admin.users.form',
                component: () => import('../components/Dashboard/Users/UserProfile.vue'),
                meta: {requiresAuth: true, title: 'Users'},
            },
            // Admin Devices
            {
                path: '/app/admin/devices',
                name: 'admin.devices.list',
                component: () => import('../components/Dashboard/Devices/Admin/List.vue'),
                meta: {requiresAuth: true, title: 'Devices'}
            },
            {
                path: '/app/admin/devices/logs/:id',
                name: 'admin.devices.logs',
                component: () => import('../components/Dashboard/Devices/Admin/Logs.vue'),
                meta: {requiresAuth: true, title: 'Device Logs'}
            },
            {
                path: '/app/admin/devices/create',
                name: 'admin.devices.create',
                component: () => import('../components/Dashboard/Devices/Admin/Create.vue'),
                meta: {requiresAuth: true, title: 'New device'}
            },
            {
                path: '/app/admin/devices/edit/:id',
                name: 'admin.devices.edit',
                component: () => import('../components/Dashboard/Devices/Admin/Edit.vue'),
                meta: {requiresAuth: true, title: 'Edit device'}
            },
            {
                path: '/app/admin/blogs-create',
                name: 'app.blogs.create',
                component: () => import('../components/Dashboard/Blogs/Create.vue'),
                meta: {requiresAuth: true, title: 'Create a blog'}
            },
            {
                path: '/app/admin/blogs-edit/:slug',
                name: 'app.blogs.edit',
                component: () => import('../components/Dashboard/Blogs/Edit.vue'),
                meta: {requiresAuth: true, title: 'Edit a blog'}
            },
            {
                path: '/app/admin/blogs',
                name: 'app.blogs.list',
                component: () => import('../components/Dashboard/Blogs/List.vue'),
                meta: {requiresAuth: true, title: 'Blogs'}
            },
            {
                path: '/app/admin/requests',
                name: 'app.requests.list',
                component: () => import('../pages/Requests.vue'),
                meta: {requiresAuth: true, title: 'Requests'}
            },
            {
                path: '/app/admin/subscribers',
                name: 'app.subscribers',
                component: () => import('../pages/Subscribers.vue'),
                meta: {requiresAuth: true, title: 'Subscriptions'}
            },
            {
                path: '/app/admin/team',
                name: 'app.team',
                component: () => import('../pages/AppTeams.vue'),
                meta: {requiresAuth: true, title: 'Team'}
            },
            {
                path: '/app/admin/stats',
                name: 'app.stats',
                component: () => import('../pages/Stats.vue'),
                meta: {requiresAuth: true, title: 'Statistics'}
            },
        ],

    },
    {
        path: '/:catchAll(.*)*',
        name: 'not-found',
        component: () => import('../pages/NotFound.vue'),
        meta: {title: 'Not Found'}
    },
]

export default routes